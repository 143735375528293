<template>

  <v-main style="background-color:#F2F3F8">

     <v-container>

       <div class="ma-5">
          <v-alert
                v-model="alertShow"
                color="red darken-1"
                dark
                icon="mdi-hammer-screwdriver"
                border="left"
                elevation="2"
                prominent>

                This page is scheduled for work. Currently, it is a prototype page.
          </v-alert>
 
       </div>

     <v-row>

      <v-col
        cols="12"
        sm="2">
      </v-col>

          <v-col
            cols="12"
            sm="8">

      <v-container 
        fluid
        style="mt-5; ">
      <v-subheader><h2>Beginner Quest</h2></v-subheader>
 
        <v-row>
          <v-card @click="gotoDetail(i)"
                  class="ma-2 auto"
                  v-for="i in 15"
                  :key="i"
                  width="350"
                  style="margin-bottom:15px"
                  elevation="3"
                >
                <v-progress-linear color="blue darken-3" background-color="teal accent-1" value="30"></v-progress-linear>
                <v-card-title>
                  Quest
                </v-card-title>

               <v-avatar
               class="ml-3"
                rounded=""
                color="blue"
              >
                <v-icon dark>
                  mdi-alarm
                </v-icon>
              </v-avatar>
               
                  <v-card-subtitle class="pb-0">
                    3일전에 게시됨
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div>Easy</div>

                    <div>다른 계졍으로 0.001 EOS 전송하기</div>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <div class="ma-3 text-caption">

                   <div  >
                     <v-icon color="blue" small>mdi-credit-card-outline</v-icon>
                      20 EOS
                  </div>
                   <div  >
                     <v-icon color="blue" small>mdi-emoticon-cool</v-icon>
                      150 Exp
                  </div>
                   <div>
                     <v-icon color="blue" small>mdi-account-group</v-icon>
                     124 Compeleted ( 76 Remains)
                  </div>
 

                  </div>
                  
                
          </v-card>
  
        </v-row>

      </v-container>

      <br><br>

       <v-container 
        fluid
        style="mt-5;">
      <v-subheader><h2>Master Quest</h2></v-subheader>
 
        <v-row>
          <v-card @click="gotoDetail(i)"
                  class="ma-2 auto"
                  v-for="i in 15"
                  :key="i"
                  width="350"
                 
                  style="margin-bottom:15px"
                >
                <v-progress-linear color="teal accent-4" background-color="teal accent-1" value="30"></v-progress-linear>
                <v-card-title>
                  Quest
                </v-card-title>

               <v-avatar
               class="ml-3"
                rounded=""
                color="blue"
              >
                <v-icon dark>
                  mdi-alarm
                </v-icon>
              </v-avatar>
               
                  <v-card-subtitle class="pb-0">
                    1일전에 게시됨
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <br>
                    <!-- <div>Whitehaven Beach</div> -->

                    <div>Proxy에 투표하기</div>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <div class="ma-3 text-caption">

                   <div  >
                     <v-icon color="blue" small>mdi-credit-card-outline</v-icon>
                      0.1 EOS
                  </div>
                   <div  >
                     <v-icon color="blue" small>mdi-emoticon-cool</v-icon>
                      550 Exp
                  </div>
                   <div>
                     <v-icon color="blue" small>mdi-account-group</v-icon>
                     124 Compeleted ( 76 Remains)
                  </div>
 

                  </div>
                  
                
          </v-card>
  
        </v-row>

      </v-container>



          </v-col>



    </v-row>


     </v-container>

 

  
     

  </v-main>
 
</template>

<script>
export default {
  
  data() {
    return {
      levelPercent:73,
      alertShow:true,
      menuItems: [
        {
          icon: 'mdi-folder-star-multiple',
          text: 'Top Rated',
        },
        {
          icon: 'mdi-star',
          text: 'Beginner',
        },
        {
          icon: 'mdi-shield-star',
          text: 'Intermediate',
        },
        {
          icon: 'mdi-police-badge',
          text: 'Master',
        },
         {
          icon: 'mdi-information',
          text: 'Read Basic Info About EOS',
        },
        {
          icon: 'mdi-ticket-confirmation',
          text: 'Etc',
        },
      ],
      selectedMenu: 0,
      questLineIcons: [
        {
          color: 'green lighten-2',
          icon: 'mdi-star',
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
        },
      ],
      e6:1,
      createQuestDialog:false,
       ex3: { label: 'Max Target', val: 50, color: 'orange darken-3' },
    }
  },
  methods: {

    createQuest()
    {
      this.createQuestDialog = true;

    },
    gotoDetail(qIndex)
    {
      //this.$router.push({ name: 'QuestDetail', params: { QuestID: qIndex } })
    }

  }
,mounted() {
  //console.log(this.$route.params);
  this.$vuetify.goTo(0)
},

}
</script>

